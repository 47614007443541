// STYLE
// import "antd/dist/antd.css";
import "./assets/css/style.scss";
import { createGlobalStyle } from "styled-components";

// COMPONENT
import Routes from "./routes";
import Frame from './components/Frame'

import { DBConfig } from "./utils";
import { initDB } from "react-indexed-db-hook";

initDB(DBConfig);

function Root() {
  return (
    <>
      <CssReset />
      <Frame>
        <Routes />
      </Frame>
    </>
  );
}


const CssReset = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-style: normal
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size:14px;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a:link,a:visited{
    text-decoration: none;
    color:inherit;
  }
  html,body{
    -webkit-text-size-adjust: none;
    position: relative;
  }
  html {
    height: 100%;
    overflow-y: scroll;
  }
  body {
    &::-webkit-scrollbar {
      display: none; 
      -ms-overflow-style: none;
    }
  }
  input,button{
    background-color:transparent;
    border:none;
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
  #root{
    height: 100%;
    min-height: 100%;
    position: relative;
  }
  input[type="password"] {
    font-family: sans-serif !important;
  }
`;

export default Root;

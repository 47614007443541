const ReplaceNewLine = ({ str }) => {
  return str.match(/↵|\r\n|\\r|\\n/g) ? (
    <>
      {str.split("\\n").map((line, index) => (
        <span key={`${line}-${index}`}>
          {line}
          <br />
        </span>
      ))}
    </>
  ) : (
    <span>{str}</span>
  );
};

export default ReplaceNewLine;
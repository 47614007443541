// REACT
import { Children } from 'react';

// STYLE
import { LayoutInner, PALLETE } from '../ui';

// REDUX
import { useSelector } from 'react-redux';

// COMPONENT
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

// MODULE
import styled, { css } from 'styled-components';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';


const Frame = ({ children }) => {
  const isLoading = useSelector((state) => state.global.isLoading);
  const element = Children.only(children); 

  return (
    <FrameContainer>
      <FullPageLoading load={isLoading ? "load" : ""}>
        <Spin indicator={<Loading3QuartersOutlined style={{ color: PALLETE.KEY, fontSize: 40 }} spin />} />
      </FullPageLoading>
      <AppHeader />
      <RouteWrap>
        {element}
      </RouteWrap>
      <AppFooter />

    </FrameContainer>
  );
}

const FrameContainer = styled.div`
  width: 100%;
  
`;

const RouteWrap = styled(LayoutInner)`
  min-height: 100vh;  
  padding: 11.5rem 0 2.5rem 0; 
  min-height: calc(100vh - 12rem);

  @media screen and (max-width: 760px) {
    padding: 9.5rem 0 2.5rem 0;
  }

`;

const FullPageLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  align-items: center;
  justify-content: center;

  ${({ load }) => load === "load" ? "display: flex;" : "display: none;"}
`;



export default Frame;
// REACT
import React, { useEffect } from "react";

import styled from "styled-components";
import naverIcon from '../assets/images/ic-naver-login.png'

// STYLE
import { Button } from '../components/ui'


const NaverLogin = ({ clientId, callbackUrl, onSuccess, onFailure }) => {
  const { naver } = window;

  const Naver = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId,
      callbackUrl,
      isPopup: true,
      loginButton: { color: "green", type: 1, height: "69" }
    });

    naverLogin.init();
    
    if (!window.opener) {
      naver.successCallback = function (data) {
        return onSuccess(data);
      };
      naver.FailureCallback = onFailure;
    } else {
      naverLogin.getLoginStatus(function (status) {
        if (!status || window.location.hash.indexOf("#access_token") === -1) {
          return;
        }
        const location = window.location.href.split("=")[1];
        const accessToken = location.split("&")[0];
        const response = {
          ...naverLogin.user,
          accessToken
        };
        window.opener.naver.successCallback(response);
        window.close();
      });
    }
  };

  useEffect(() => {
    Naver();
    return () => { };
  }, []);

  return <SocialButton size='large' id="naverIdLogin"></SocialButton>;
};

const SocialButton = styled(Button)`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  flex: none;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background: #00ca3b url(${naverIcon}) center / 45% no-repeat;
  padding: 0;
  overflow: hidden;
  & img {
    display: none;
  }
`;

export default NaverLogin;

// REDUX
import { createSlice } from '@reduxjs/toolkit'

// UTIL
import { isLogin } from "../../utils";

export const counterSlice = createSlice({
  name: 'global',
  initialState: {
    isLogged: isLogin(),
    hasFrame: ["/home", "/notice"],
    isLoading: false,
  },
  reducers: {
    login: (state, data) => {
      const { payload } = data;
      localStorage.setItem("accessToken", payload.token);
      localStorage.setItem("userEmail", payload.email);
      localStorage.setItem("uid", payload.uid);
      localStorage.setItem("userName", payload.name);
      localStorage.setItem("userProfile", payload.profile);
      localStorage.setItem("premium", `${payload.premium}`);
      localStorage.setItem("isPublic", `${payload.pub}`);

      state.isLogged = true;
    },
    logout: (state) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("uid");
      localStorage.removeItem("userName");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("premium");
      localStorage.removeItem("isPublic");
      state.isLogged = false;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    }
    
  },
})

export const { login, logout, loading } = counterSlice.actions;

export default counterSlice.reducer


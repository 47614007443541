//STYLE
import { Modal } from "antd";
import ReplaceNewLine from "./ui/ReplaceNewLine";

const Alert = {
  info: ({ title, content, ok }) => {
    Modal.info({
      title,
      destroyOnClose: true,
      content: <ReplaceNewLine str={content} />,
      className: `custom-alert ${title ? "has-title" : ""}`,
      okText: "확인",
      onOk() {
        if (ok) {
          ok();
        }
      }
    });
  },
  confirm: ({ content, ok, title, okText, cancelText }) => {
    Modal.confirm({
      title,
      destroyOnClose: true,
      content: <ReplaceNewLine str={content} />,
      className: `custom-alert ${title ? "has-title" : ""}`,
      cancelText: cancelText ? cancelText : "취소",
      okText: okText ? okText : "확인",
      onOk() {
        if (ok) {
          ok();
        }
      }
    });
  }
}

export default Alert;
import styled, { css } from 'styled-components';

export const PALLETE = {
  KEY: "#ff4882",
  SUB: "#ffedf2"
};

export const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.bgColor || props.bgColor === "white" ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.8)"};
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.visible ? "flex" : "none")};
`;


export const LayoutInner = styled.div`
  width: 160rem;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 120rem;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  font-size: 1.6rem;
  height: 4.5rem;
  padding: 0 1.6rem;
  border-radius: 1rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b2b2b2;
  color: #fff;
  flex: none;
  border: none;
  cursor: pointer;
  min-width: 0;

  ${(props) => props.width && `width: ${props.width};`}

  ${(props) => props.color === 'primary' && css`
      color: #fff !important;
      background-color: ${PALLETE.KEY};
    `
  }

  ${(props) => props.color === 'sub' && css`
      color: ${PALLETE.KEY} !important;
      background-color: ${PALLETE.SUB};
    `
  }

  ${(props) => props.size === 'large' && css`
      height: 6rem;
      border-radius: 1.4rem;
      font-size: 1.7rem;
      padding: 0 2.2rem;
    `
  }
  ${(props) => props.size === 'small' && css`
      font-size: 1.4rem;
      height: 3.2rem;
      padding: 0 0.8rem;
      border-radius: 0.8rem;
    `
  }
  ${(props) => props.size === 'mini' && css`
      font-size: 1.3rem;
      height: 2.8rem;
      padding: 0 0.6rem;
      border-radius: 0.5rem;
    `
  }
  

`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;  
  justify-content: center;
  &>*+* {
    margin-left: 2.5rem;
  }

  @media screen and (max-width: 760px) {
    &>*+* {
      margin-left: 1.5rem;
    }
  }
`;


export const Form = styled.div`
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  padding: 5rem 0;

  @media screen and (max-width: 760px) {
    padding: 3rem 2.5rem;
  }
`;

export const FormTitle = styled.h2`
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.55;
  margin-bottom: 4.5rem;
  color: #222;

  ${(props) => props.align && `text-align: ${props.align};`}
`;

export const FormInput = styled.input`
  border: 1px solid #ddd;
  padding: 0 1.5rem;
  border-radius: 1.4rem;
  height: 6rem;
  line-height: 6rem;
  width: 100%;
  font-size: 1.5rem !important;
  color: #222;

  &::placeholder {
    font-size: 1.5rem !important;
    color: #b2b2b2;
  }
`;

export const FormLabel = styled.p`
  font-size: 1.6rem;
  margin: 3rem 0 2rem 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.05rem;
  color: #222;

  ${(props) => props.require && css`
    padding-left: 1.1rem;
    position: relative;

    &::before {
      content: "*";
      display: block;
      position: absolute;
      left: 0;
      top: 0.4rem;
      color: ${PALLETE.KEY};
      font-size: 1.4rem;
      line-height: 1;
    }
  `}
`;

// export const Form
// REACT
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// COMPONENTS
import Intro from '../pages/Intro'
// import Test from '../pages/WorkerTest'
import Login from '../pages/Login'
import Error404 from '../pages/Error404'
import NotSupport from '../pages/NotSupport';
import PrivateRoute from '../routes/PrivateRoute'

// UTIL
import { getAgent } from '../utils';

function Router() {
  const [isSupported] = useState(getAgent() === "Chrome" || getAgent() === "Safari");

  return (
      <Routes>
        {
          isSupported ? (
            <>              
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                {/* <Route path="/test" element={<Test />} /> */}
                <Route path="/" element={<Intro />} />
              </Route>
              <Route path="*" element={<Error404 />} />
            </>
          ) : (
            <Route path="*" element={<NotSupport />} />
          )
        }
      </Routes>
  );
}
export default Router;
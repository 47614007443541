// REACT
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

// STYLE
import { Form, FormInput, FormLabel, Button, FormTitle, ButtonWrap } from '../components/ui'
import Alert from '../components/Alert'

// MODULE
import styled, { css } from 'styled-components'
import KakaoLogin from 'react-kakao-login'
import { OAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { useIndexedDB } from 'react-indexed-db-hook';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// REDUCER
import { login } from '../redux/reducers/global'

// COMPONENT
import kakaoIcon from '../assets/images/ic-kakao-login.png'
import googleIcon from '../assets/images/ic-google-login.svg'
import appleIcon from '../assets/images/ic-apple-login.png'
import NaverLogin from '../components/NaverLogin'

// UTIL
import { EMAIL_REGEX, getSid, generateSid, setSid, networkError, setSocialInfo } from '../utils'
import { signInWithApple, signInWithGoogle } from '../utils/firebase'

// API
import { loginAPI, setDeviceAPI } from '../api'

const MESSAGE = {
  EMPTY_EMAIL: "이메일을 입력해주세요.",
  EMPTY_PW: "비밀번호를 입력해주세요.",
  INVALID_EMAIL: "존재하지 않는 계정입니다.",
  INVALID_PW: "비밀번호가 일치하지 않습니다.",
  EMAIL_REG: "이메일 형식을 확인해주세요."
};


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.global.isLogged);
  const db = useIndexedDB('gallery');

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  

  useEffect(() => {
    if(isLogged) {
      navigate("/");
    }
  
    return () => { }
  }, [isLogged])
  

  const handleClickLogin = async (social = "", token = "") => {
    if (isLoading) return;
    const id = email.trim();
    const pw = password.trim();

    if (id.length <= 0 && social === '') {
      Alert.info({ content: MESSAGE.EMPTY_EMAIL });
    } else if (!id.match(EMAIL_REGEX) && social === '') {
      Alert.info({ content: MESSAGE.EMAIL_REG });
    } else if (pw.length <= 0 && social === '') {
      Alert.info({ content: MESSAGE.EMPTY_PW });
    } else {
      // LOGIN API REQUEST
      setIsLoading(true);

      try {
        if (!getSid()) {
          // sid가 없으면 생성 후 localstorage에 저장
          setSid(generateSid());
        }

        const request = {
          password: social === '' ? pw : token,
          sid: getSid(),
          social: social === '' ? "normal" : social
        };

        if (social === '') {
          request.email = id;
        }
        const { data } = await loginAPI(request);
        setIsLoading(false);

        dispatch(login(data))
        await setDeviceAPI();

        try {
          await db.clear();
        } catch {}

        navigate("/");

      } catch (error) {
        setIsLoading(false);
        if(error.code) {
          if (error.code === 405) {
            networkError('존재하지 않는 계정입니다.')
          } else if (error.code === 432) {
            networkError(`${error.message}`);
          } else if (error.code === 460) {
            networkError("탈퇴 처리된 계정입니다.");
          } else {
            networkError(`${error.code} - ${error.message}`);
          }
        } else {
          networkError(error.message);
        }
      }

    }
  }

  const kakaoOnSuccess = async (data) => {
    const { profile, response } = data;
    const account = profile.kakao_account;
    const token = response.access_token;

    setSocialInfo({
      name: account.profile.nickname,
      email: account.email,
      token,
      type: "kakaotalk"
    });

    handleClickLogin("kakaotalk", token);
  }

  const naverOnSuccess = async (data) => {
    const { accessToken, email, name } = data;

    setSocialInfo({
      name,
      email: email,
      token: accessToken,
      type: "naver"
    });

    handleClickLogin("naver", accessToken);
  }

  const handleClickAppleLoginBtn = async () => {
    try {
      const result = await signInWithApple();
      const user = result.user;
      const credential = OAuthProvider.credentialFromResult(result);

      if (user && credential) {
        setSocialInfo({
          name: user.displayName,
          email: user.email,
          token: credential.idToken,
          type: "apple"
        });
        handleClickLogin("apple", credential.idToken);
      }
    } catch (error) {
      if (error.code !== "auth/popup-closed-by-user") {
        Alert.info({ content: "애플 로그인에 실패했습니다." });
      }
    }
  }


  const handleClickGoogleLoginBtn = async () => {
    try {
      const result = await signInWithGoogle();
      const user = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);

      if (user && credential) {
        setSocialInfo({
          name: user.displayName,
          email: user.email,
          token: credential.idToken,
          type: "apple"
        });
        handleClickLogin("google", credential.idToken);
      }
    } catch (error) {
      if (error.code !== "auth/popup-closed-by-user") {
        Alert.info({ content: "구글 로그인에 실패했습니다." });
      }
    }
  }
  

  return(
    <Form>
      <FormTitle align='center'>서비스 이용을 위해<br/>로그인이 필요해요!</FormTitle>
      <FormLabel>이메일</FormLabel>
      <FormInput placeholder='이메일을 입력해주세요.' onChange={e => setEmail(e.target.value)} value={email} />

      <FormLabel>비밀번호</FormLabel>
      <FormInput type='password' placeholder='비밀번호를 입력해주세요' onChange={e => setPassword(e.target.value)} value={password} />

      <LoginButtonWrap>
        <Button size='large' color='primary' width='100%' onClick={() => handleClickLogin()}>로그인</Button>
      </LoginButtonWrap>

      <SocialButtonWrap>
        <KakaoLogin
          token={process.env.REACT_APP_KAKAO_CLIENT_ID}
          onSuccess={kakaoOnSuccess}
          onFail={() => Alert.info({ content: "카카오톡 로그인에 실패했습니다." })}
          render={({ onClick }) => {
            return (
              <SocialButton 
                size='large' 
                type='kakao' 
                onClick={e => {
                  e.preventDefault();
                  onClick();
                }}
              />
            )
          }}
        />
        <NaverLogin
          clientId={`${process.env.REACT_APP_NAVER_CLIENT_ID}`}
          callbackUrl={`${process.env.REACT_APP_URL}/login`}
          onSuccess={naverOnSuccess}
          onFailure={() => Alert.info({ content: "네이버 로그인에 실패했습니다." })}
        />        
        <SocialButton size='large' type='google' onClick={handleClickGoogleLoginBtn}></SocialButton>
        <SocialButton size='large' type='apple' onClick={handleClickAppleLoginBtn}>
        </SocialButton>
      </SocialButtonWrap>

      

    </Form>
  );
}


const SocialButtonWrap = styled(ButtonWrap)`
  margin-top: 3.5rem;
  justify-content: center;
`;

const SocialButton = styled(Button)`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  flex: none;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;


  ${(props) => props.type==='kakao' && css`
    background: #fadd00 url(${kakaoIcon}) center / 45% no-repeat;
  `}
  ${(props) => props.type==='google' && css`
    border: 1px solid #ddd;
    background: #fff url(${googleIcon}) center / 45% no-repeat;
  `}
  ${(props) => props.type==='apple' && css`
    background: #000 url(${appleIcon}) center / 45% no-repeat;
    &>* {
      display: block;
      width: 100%;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
        display: none;
      }
    }
  `}

  @media screen and (max-width: 760px) {
    width: 6.5rem;
    height: 6.5rem;
  }
    
  
`;

const LoginButtonWrap = styled(ButtonWrap)`
  margin-top: 4rem;
`;


export default Login;
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DB_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`
};
// Initialize Firebase
initializeApp(firebaseConfig);

const providerApple = new OAuthProvider('apple.com');
providerApple.addScope("email");
providerApple.setCustomParameters({ prompt: "select_account" });

const providerGoogle = new GoogleAuthProvider();
providerGoogle.setCustomParameters({ prompt: "select_account" });


const firebaseAuth = getAuth();


export const signInWithGoogle = () => signInWithPopup(firebaseAuth, providerGoogle);
export const signInWithApple = () => signInWithPopup(firebaseAuth, providerApple);

// export const firestore = firebase.firestore();

// const providerGoogle = new firebase.auth.GoogleAuthProvider();
// const providerApple = new firebase.auth.OAuthProvider("apple.com");
// // GoogleAuthProvider 클래스를 authentication 라이브러리에서 사용할 수 있도록 불러오는 코드.

// providerApple.addScope("email");
// providerGoogle.setCustomParameters({ prompt: "select_account" });
// providerApple.setCustomParameters({ prompt: "select_account" });
// // signIn이랑 authentication을 위해서 GoogleAuthProvider를 사용할 때마다 구글 팝업을 항상 띄우기를 원한다는 의미이다.

// export const signInWithGoogle = () => auth.signInWithPopup(providerGoogle);
// export const signInWithApple = () => auth.signInWithPopup(providerApple);
// // signInWithPopup 메소드는 여러 파라미터를 받을 수 있다. 트위터, 페이스북, 깃허브 등으로도 로그인이 필요할 수도 있으므로.
// // 여기에서는 google로 signIn할 것이기 때문에, 파라미터로 위에서 정의한 provider를 넣어준다.

// export default firebase;

// COMPONENT
import errorImg from "../assets/images/404-error.png";

// STYLE
import { Button, ButtonWrap } from '../components/ui'

// MODULE
import styled from 'styled-components'

const Error404 = () => {

  return(
    <ErrorWrap>
      <img src={errorImg} alt="존재하지 않는 페이지입니다." />
      <p>존재하지 않는 페이지입니다.</p>

      <ButtonWrap>
        <Button as="a" color="primary" href="/login">메인으로</Button>
      </ButtonWrap>
    </ErrorWrap>
  );
}

const ErrorWrap = styled.div`
  img {
    width: 30rem;
    margin: 0 auto;
    display: block;
    margin: 0 auto;
  }
  p {
    margin: 4rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    color: #4db5d6;
  }
`;


export default Error404;
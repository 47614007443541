// REACT
import { useLocation } from 'react-router'
import { useNavigate } from "react-router-dom";

// STYLE
import { LayoutInner, PALLETE, Button } from '../ui';
import Alert from '../../components/Alert'

// COMPONENT
import logo from "../../assets/images/app-logo.svg";

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// REDUCER
import { logout } from '../../redux/reducers/global'

// API
import { logoutAPI } from '../../api'

// MODULE
import styled from 'styled-components';
import { useIndexedDB } from 'react-indexed-db-hook';
import { ReactSVG } from "react-svg";
import { LogoutOutlined } from '@ant-design/icons'

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.global.isLogged);
  const location = useLocation();
  const db = useIndexedDB('gallery');
  const { pathname } = location;

  const handleClickLogout = () => {
    Alert.confirm({
      content: "로그아웃하시겠습니까?",
      ok: async () => {
        try {
          await logoutAPI();
          await db.clear();
          
          dispatch(logout());
          navigate("/login");
        } catch {
          dispatch(logout());
          navigate("/login");
        }
      }
    });
  };

  return (
    <Header>
      <LayoutInner as="nav">
        <ReactSVG src={logo} className="app-logo" />
        {/* <NavMenu>
          <Menu href="/" active={pathname === "/" ? "active" : ""}>홈</Menu>
        </NavMenu> */}


        <MyMenu>
          {isLogged ? <Menu onClick={handleClickLogout}>로그아웃</Menu> : <Menu href="/login" active={pathname === "/login" ? "active" : ""}>로그인</Menu>}
        </MyMenu>

        {
          isLogged && 
          <MobileLogoutButton onClick={handleClickLogout}>
            <LogoutOutlined style={{ color: PALLETE.KEY, fontSize: 22 }} />
          </MobileLogoutButton>
        }

      </LayoutInner>
    </Header>
  );
}

const Header = styled.header`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 9rem;
  z-index: 15;
  border-bottom: 1px solid #f2f2f2;

  nav {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .app-logo {
    display: block;
    width: 14rem;
  }
  
  @media screen and (max-width: 760px) {
    /* MOBILE */
    height: 7rem;

    nav {
      justify-content: center;
      position: relative;
    }
  }
`;

const Menu = styled.a`
  display: block;
  padding: 1.5rem 2.5rem;
  color: #222;
  transition: all 0.2s ease-in-out;

  ${(props) => props.active === "active" && `color: ${PALLETE.KEY} !important;` }

  &:hover {
    color: ${PALLETE.KEY};
  }
`;


const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4rem;
  font-size: 1.7rem;
  letter-spacing: -0.05rem;

  @media screen and (max-width: 760px) {
    display: none;
  }
`;

const MyMenu = styled(NavMenu)`
  margin-left: auto;
  font-size: 1.5rem;
`;

const MobileLogoutButton = styled(Button)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  padding: 1rem;
  background: transparent;
  @media screen and (min-width: 761px) {
    display: none;
  }
`;


export default AppHeader;

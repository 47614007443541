/* eslint-disable no-useless-escape */
import Alert from "../components/Alert";
import axios from "axios";
import moment from "moment";

export const DATE_FORMAT = "YYYY.MM.DD HH:mm";
export const EMAIL_REGEX = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/g;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W][^\s]{7,20}$/g;
export const CONTACT_REGEX = /(^010)+\d{8}$/g;
export const MAX_SELECT = 50;
export const UPLOAD_SIZE = 2000;
export const LIST_MAX_COUNT = 10;
export const THUMBNAIL_SIZE = 200;
export const MAX_VIDEO_SIZE = 300;
export const MIN_VIDEO_SIZE = 10;
export const MAX_DURATION_MINUTE = 10;

export const getToken = () => localStorage.getItem("accessToken");

export const isLogin = () => (getToken() ? true : false);

export const isPremium = () => JSON.parse(`${localStorage.getItem("premium")}`);

export const getUserEmail = () => (localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : "");

export const getUid = () => (localStorage.getItem("uid") ? localStorage.getItem("uid") : "");

export const getSid = () => localStorage.getItem("sid");

export const setSid = (sid) => localStorage.setItem("sid", sid);

export const getUserName = () => (localStorage.getItem("userName") ? localStorage.getItem("userName") : "");

export const getUserProfile = () => (localStorage.getItem("userProfile") ? localStorage.getItem("userProfile") : "");

export const amountCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const networkError = (msg = "잠시후에 시도해주세요.") => Alert.info({ content: msg });

export const phoneNumberFormat = (phone) => phone.replace(/(\d{3})(\d{4})(\d{4})/g, "$1-$2-$3");

export const setSocialInfo = (payload) => localStorage.setItem("social", JSON.stringify(payload));

export const removeSocialInfo = () => localStorage.removeItem("social");

export const setUploadImgQuality = (quality) => localStorage.setItem("imgQuality", quality);

export const setUploadVideoQuality = (quality) => localStorage.setItem("videoQuality", quality);

export const getUploadImgQuality = () => (localStorage.getItem("imgQuality") ? `${localStorage.getItem("imgQuality")}` : "high");

export const getUploadVideoQuality = () => (localStorage.getItem("videoQuality") ? `${localStorage.getItem("videoQuality")}` : "high");

export const setFeedDB = (gid, uid, idx) => sessionStorage.setItem(`feed${gid}${uid}`, idx);

export const getFeedDBIdx = (gid, uid) => sessionStorage.getItem(`feed${gid}${uid}`);



export const getSocialInfo = () => {
  const data = localStorage.getItem("social");
  return data ? JSON.parse(data) : null;
};

export const objToFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).map(item => formData.append(item, obj[item]));
  return formData;
};

export const generateSid = () => {
  let text = "";
  const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 10; i++) text += str.charAt(Math.floor(Math.random() * str.length));
  return text;
};

export const convertMediaSize = (size, type) => {
  const decimal = 2; // 소수점 몇번째 자리까지 보여줄껀지
  const kByte = 1024; // 1024 바이트
  const mByte = kByte * kByte; // 1,048,576 바이트
  const gByte = mByte * kByte; // 1,073,741,824 바이트

  if (type === "KB") {
    return parseInt((size / kByte).toFixed(decimal));
  } else if (type === "MB") {
    return parseInt((size / mByte).toFixed(decimal));
  } else {
    return parseInt((size / gByte).toFixed(decimal));
  }
};

export const getAgent = () => {
  const agent = navigator.userAgent.toLowerCase();
  if (agent.indexOf("msie 9") >= 0) return "IE 9";
  else if (agent.indexOf("msie 10") >= 0) return "IE 10";
  else if (agent.indexOf("rv:11.0") >= 0) return "IE 11";
  else if (agent.indexOf("edge/") >= 0) return "Edge";
  else if (agent.indexOf("edg/") >= 0) return "Edge";
  else if (agent.indexOf("chrome") >= 0) return "Chrome";
  else if (agent.indexOf("safari") >= 0) return "Safari";
  else if (agent.indexOf("firefox") >= 0) return "Firefox";
  else if (agent.indexOf("opera") >= 0) return "Opera";
  else return "";
};

export const setCookie = (name, value, options) => {
  options = {
    path: "/",
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = (name) => {
  setCookie(name, "", {
    "max-age": -1
  });
};

export const $http = (option) => (useToken) => {
  if (useToken) {
    option.headers = {
      ...option.headers,
      Authentication: `${getToken()}`
    };
  }
  return axios(option);
};

export const cuttingText = (text, length) => (text.length >= length ? `${text.substring(0, length)} ...` : text);

export const onlyNumberInput = (payload) => {
  const { value, limitLen, changeEvent } = payload;
  const onlyNumReg = /^[0-9\,]+$/g;
  if (value.match(onlyNumReg)) {
    if ((limitLen && value.length <= limitLen) || !limitLen) {
      changeEvent();
    }
  } else if (value === "") {
    changeEvent();
  }
};

export const getTimeFromNow = (date) => {
  // 1시간전 까지만 방금전, 1분전, ...1시간전
  // 1시간 후 ~ 오늘 - 시간으로 표시 오후 12:09
  // 어제
  // 나머지는 날짜
  const today = moment();
  // const yesterday = moment().subtract(1, "day");
  const dateToMoment = moment(date);
  const isToday = today.isSame(dateToMoment, "day");
  // const isYesterday = yesterday.isSame(dateToMoment, "day");

  if (isToday) {
    const fromNow = today.to(dateToMoment, true);
    if (fromNow.indexOf("1시간") === -1 && fromNow.indexOf("시간") !== -1 && fromNow.indexOf("11시간") === -1) {
      return dateToMoment.format("LT");
    } else {
      return `${fromNow === "몇 초" ? "방금" : fromNow}전`;
    }
  } else {
    return dateToMoment.format("YYYY-MM-DD");
  }
};


export const DBConfig = {
  name: 'modooGallery',
  version: 4,
  objectStoresMeta: [
    {
      store: 'gallery',
      storeConfig: { keyPath: 'id' },
      storeSchema: [
        { name: 'gid', keypath: 'gid', options: { unique: false } },
        { name: 'idx', keypath: 'idx', options: { unique: true } },
        // { name: 'tag', keypath: 'tag', options: { unique: true } },
        { name: 'fileId', keypath: 'fileId', options: { unique: true } },
        { name: 'file', keypath: 'file', options: { unique: false } },
        { name: 'filename', keypath: 'filename', options: { unique: false } },
        { name: 'mimetype', keypath: 'mimetype', options: { unique: false } },
      ]
    }
  ]
};
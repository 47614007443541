// MODULE
import moment from 'moment'

// UTIL
import { $http, getSid, getAgent } from '../utils'

export const loginAPI = (payload) => {
  return new Promise((resolve, reject) => {
    $http({
      url: "/account/signin",
      method: "POST",
      data: { ...payload }
    })(false)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};

export const logoutAPI = () => {
  return new Promise((resolve, reject) => {
    $http({
      url: "/account/signout",
      method: "POST"
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};

export const setDeviceAPI = () => {
  const data = { sid: getSid(), os: "Windows", app: getAgent(), device: "WEB" };
  return new Promise((resolve, reject) => {
    $http({
      url: "/prefs/device",
      method: "POST",
      data
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};

export const getGalleryList = (uid) => {
  const params = uid ? { uid, onlymanual: true } : {};
  return new Promise((resolve, reject) => {
    $http({
      url: "/galleries",
      method: "GET",
      params
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};

export const getGalleryFeedCount = (gid) => {
  const params = {
    gid,
    start: moment(new Date("1980-01-01")).valueOf(),
    end: moment().valueOf(),
  };

  return new Promise((resolve, reject) => {
    $http({
      url: `/galleries/${gid}/feed/files/count`,
      method: "GET",
      params
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};


export const getGalleryFeedFiles = (gid, count) => {
  const params = {
    gid,
    from: 0,
    count
  };

  return new Promise((resolve, reject) => {
    $http({
      url: `/galleries/${gid}/feed/files`,
      method: "GET",
      params
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};


export const getFeedFiles = () => {
  const params = {
    from: 0,
    count: 100
  };

  return new Promise((resolve, reject) => {
    $http({
      url: "/galleries/feed/files",
      method: "GET",
      params
    })(true)
      .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
      .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
  });
};


// export const getMediaFeedFiles = (params) => {
//   return new Promise((resolve, reject) => {
//     $http({
//       url: "/media/feed/files",
//       method: "GET",
//       params
//     })(true)
//       .then(response => (response.data.code === 200 ? resolve(response.data) : reject(response.data)))
//       .catch(e => (!e.response ? reject(e) : reject(e.response.data)));
//   });
// };
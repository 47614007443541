// REDUX
import { useSelector } from 'react-redux';

// REACT ROUTER
import { useLocation } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'

export const publicPage = ['login'];

function PrivateRoutes() {
  const location = useLocation();
  const { pathname, search } = location;
  const from = pathname + search;

  const isLogged = useSelector((state) => state.global.isLogged);

  const passPage = publicPage.includes(pathname);

  return isLogged || passPage ? <Outlet /> : <Navigate to="/login" replace state={{ from }} />
}

export default PrivateRoutes
// REACT
import { useEffect, useState } from 'react';

// REDUX
import { useDispatch } from 'react-redux';

// REDUCER
import { loading } from '../redux/reducers/global'

// STYLE
import { Form, PALLETE, Button } from '../components/ui'

// COMPONENT
import galleryNoImage from '../assets/images/gallery-noimg.png'

// MODULE
import styled from 'styled-components'
import { Spin, Drawer, Progress } from 'antd';
import { Loading3QuartersOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { createWorkerFactory, useWorker, terminate } from '@shopify/react-web-worker';
import { useIndexedDB } from 'react-indexed-db-hook';

// API
import { getGalleryList, getGalleryFeedCount, getGalleryFeedFiles } from '../api'

// UTIL
import { networkError } from '../utils'
import Alert from '../components/Alert';

import JSZip from 'jszip';

const createWorker = createWorkerFactory(() => import('../worker'));


const Intro = () => {
  const worker = useWorker(createWorker);
  const dispath = useDispatch();
  const db = useIndexedDB('gallery');
  // const uid = getUid();

  const [gallery, setGallery] = useState({ my: [], neighbor: [] });
  const [requestGalleryToggle, setRequestGalleryToggle] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [zipCount, setZipCount] = useState(0);
  const [zipPercentage, setZipPercentage] = useState(0);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const callGalleryList = async () => {
    try {
      const { data } = await getGalleryList();

      setGallery({
        my: data.galleries.filter(data => data.owner),
        neighbor: data.galleries.filter(data => !data.owner)
      });
      setRequestGalleryToggle(true);
    } catch (e) {
      networkError(e.message);
      setRequestGalleryToggle(true);
    }
  };

  const download = ({ fileName, data, url }) => {
    return new Promise((resolve, reject) => {
      try {
        const aTag = document.createElement('a');
        let url_internal = '';
        if (url) {
          url_internal = url;
        } else if (data) {
          if (!fileName) return;
          if (typeof data === 'string') {
            url_internal = URL.createObjectURL(new Blob([data]));
          } else {
            url_internal = URL.createObjectURL(data);
          }
        } else return;
        aTag.setAttribute('href', url_internal);
        aTag.setAttribute('download', fileName || '');
        aTag.click();
        URL.revokeObjectURL(url_internal);

        resolve("ok");
      } catch (error) {
        reject(error);
      }
    })
  };

  // useEffect(() => {
  //   db.getAll().then((personsFromDB) => {
  //     console.log(personsFromDB)
  //   });
  // }, []);


  const handleClickDownloadButton = async (gid, galleryName) => {

    dispath(loading(true));

    const { data } = await getGalleryFeedCount(gid);
    let totalCount = 0;
    data.months.map(payload => {
      totalCount += payload.count;
    })

    if (totalCount <= 0) {
      dispath(loading(false));

      Alert.info({
        content: "등록된 파일이 없습니다."
      })
      return;
    }

    setVisibleDrawer(true);

    try {
      // zipfile init
      const zip = new JSZip();

      const response = await getGalleryFeedFiles(gid, totalCount);
      const files = response.data.files;

      dispath(loading(false));

      setTotalFiles(files.length);


      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const tag = file.tag;
        const idx = file.idx;
        const fileId = file.fileInfo.id;
        const mimetype = file.mimetype;
        const mimetypeSplit = mimetype.split("/");
        const fileExtension = mimetypeSplit[0] === "video" ? mimetypeSplit[1] : "jpeg";

        const dbData = await db.getByID(tag);

        if (!dbData) {
          // getS3File

          const content = await worker.getS3File(fileId);


          if (content.Body) {
            const filename = `${tag}.${fileExtension}`;

            zip.file(filename, content.Body, { binary: true });

            // const dbIdx = 
            await db.add({ id: tag, gid, idx, tag, fileId, file: content.Body, filename, mimetype });
          }


        } else {

          /**
           * DB 조회
           * file 없을 경우에만 s3 get
           */

          zip.file(dbData.filename, dbData.file, { binary: true });
        }

        setZipCount(i + 1);

        let percentage = (i + 1) / files.length * 100;
        if (i === files.length) {
          percentage = 100;
        }

        setZipPercentage(percentage);
      }

      const downloadFile = await zip.generateAsync({ type: 'blob' });
      const downloadResult = await download({ fileName: `${galleryName}.zip`, data: downloadFile });

      setTimeout(() => {
        setVisibleDrawer(false);

        // init
        setZipCount(0);
        setZipPercentage(0);
        setTotalFiles(0);

      }, 1000);

    } catch (error) {
      networkError(error.message)
      setVisibleDrawer(false);
      dispath(loading(false));
    }

  }

  useEffect(() => {
    callGalleryList();

    return () => {
      terminate(worker);
    }
  }, [])


  return (
    <>
      <ZipCount placement="top" closable={false} open={visibleDrawer} key="top" height="auto">
        <p>파일을 압축 중입니다.</p>
        <p>잠시만 기다려주세요.</p>
        <p><span>{zipCount}</span>/<span>{totalFiles}</span></p>
        <Progress percent={zipPercentage} showInfo={false} strokeColor={PALLETE.KEY} />
      </ZipCount>

      <Form style={{ padding: 0 }}>
        <GalleryList>
          <Label>내 갤러리</Label>
          {
            requestGalleryToggle
              ?
              gallery.my.map(payload => {
                let { profile, gid, name, names } = payload;
                if (!profile) profile = "";
                return (
                  <Gallery key={gid}>
                    <Thumb src={profile} />
                    <Info>
                      <h2>{name}</h2>
                      <p>{names}</p>
                    </Info>
                    <DownloadButton onClick={() => handleClickDownloadButton(gid, name)}>
                      <VerticalAlignBottomOutlined style={{ color: "#fff", fontSize: 20 }} />
                    </DownloadButton>
                  </Gallery>
                )
              })
              :
              <LoadGallery>
                <Spin indicator={<Loading3QuartersOutlined style={{ color: PALLETE.KEY, fontSize: 24 }} spin />} />
              </LoadGallery>
          }
          <Label>친구</Label>
          {
            requestGalleryToggle
              ?
              gallery.neighbor.map(payload => {
                let { profile, gid, name, names } = payload;
                if (!profile) profile = "";
                return (
                  <Gallery key={gid}>
                    <Thumb src={profile} />
                    <Info>
                      <h2>{name}</h2>
                      <p>{names}</p>
                    </Info>
                    <DownloadButton onClick={() => handleClickDownloadButton(gid, name)}>
                      <VerticalAlignBottomOutlined style={{ color: "#fff", fontSize: 20 }} />
                    </DownloadButton>
                  </Gallery>
                )
              })
              :
              <LoadGallery>
                <Spin indicator={<Loading3QuartersOutlined style={{ color: PALLETE.KEY, fontSize: 24 }} spin />} />
              </LoadGallery>
          }
        </GalleryList>

      </Form>


    </>
  );
}


const GalleryList = styled.ul`
  letter-spacing: -0.05rem;
  color: #222;
`;

const Gallery = styled.li`
  padding: 1.5rem 0;
  border-bottom: 1px dashed #ddd;
  display: flex;
  align-items: center;

  @media screen and (max-width: 760px) {
    padding: 1.5rem 2.5rem;
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled(Gallery)`
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;  
  background-color: #f7f7f7;
  border-bottom: none;

  @media screen and (max-width: 760px) {
    padding: 1rem 2.5rem;
  }
`;

const Thumb = styled.div`
  flex: none;
  width: 6rem;
  height: 6rem;
  border-radius: 0.5rem;
  margin-right: 1.5rem;
  background-color: #f2f2f2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ src }) =>
    src
      ? `background-image: url(${src}),url(${galleryNoImage});`
      : `background-image: url(${galleryNoImage});`}  
`;

const Info = styled.div`
  font-size: 1.5rem;
  flex: 1;

  h2 {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    font-size: 1.4rem;
  }
`;

const LoadGallery = styled(Gallery)`
  min-height: 10rem;
  justify-content: center;
`;

const DownloadButton = styled(Button)`
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  background: ${PALLETE.KEY};
`;

const ZipCount = styled(Drawer)`
  .ant-drawer-body {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding: 2.5rem 0 0 0;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 1.7rem;
    color: #222;
    letter-spacing: -0.05rem;
    text-align: center;
    line-height: 1.45;
    p>span {
      display: inline-block;
      padding: 0 0.5rem;

      &:first-child {
        color: ${PALLETE.KEY};
      }
    }
  }

  .ant-progress {
    margin-top: 2rem;
    margin-bottom: 0;
    max-width: 100%;
  }
`;

export default Intro;
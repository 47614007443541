// COMPONENT
import errorImg from "../assets/images/404-error.png";

// STYLE
import { Button, ButtonWrap } from '../components/ui'

// MODULE
import styled from 'styled-components'

const NotSupport = () => {

  return (
    <ErrorWrap>
      <img src={errorImg} alt="지원하지 않는 브라우저입니다." />
      <p>지원하지 않는 브라우저입니다.</p>

      <ButtonWrap>
        <Button as="a" color="primary" href="https://www.google.co.kr/chrome/?brand=CHBD&gclid=CjwKCAiA8ov_BRAoEiwAOZogwdDLAdI25-345MzBwRUTr6XgoUB0iOPaI6Y_rLx63xEQ83-asfzlARoCUlsQAvD_BwE&gclsrc=aw.ds" target="_blank">Chrome 다운로드</Button>
      </ButtonWrap>
    </ErrorWrap>
  );
}

const ErrorWrap = styled.div`
  img {
    width: 30rem;
    margin: 0 auto;
    display: block;
    margin: 0 auto;
  }
  p {
    margin: 4rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    color: #4db5d6;
  }
`;


export default NotSupport;
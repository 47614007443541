// STYLE
import styled from 'styled-components';
import { LayoutInner } from '../ui';

// COMPONENT
import { Divider } from 'antd'

const AppFooter = () => {
  return (
    <Footer className="pc">
      <LayoutInner>
        <FooterInfo>
          <span className="phone">대표전화 1599-7086</span>
          <Divider type="vertical" />
          <address className="addr">서울특별시 강남구 강남대로118길 12 부경빌딩 4층</address>
        </FooterInfo>
        <Copyright>
          넥스트스트림 주식회사 대표이사 김근홍
          <br />
          사업자등록번호 : 846-86-02112 통신판매업신고증 : 제 2021-서울강남-00464 호
          <br />
          copyright (c) 2021 NextStream.,inc. All rights reserved.
        </Copyright>
      </LayoutInner>
    </Footer>
  );
}


const Footer = styled.footer`
  padding: 2.5rem;
  height: 12.3rem;
  border-top: 1px solid #f2f2f2;
  text-align: center;
  color: #666;
  .phone {
    font-weight: bold;
  }
  .phone,
  .addr {
    display: inline-block;
  }
  .ant-divider,
  .ant-divider-vertical {
    width: 2px;
    background: #666;
    height: 0.5em;
  }
  @media screen and (max-width: 1080px) {
    padding: 2.5rem;
  }
`;

const FooterInfo = styled.div`
  margin-bottom: 12px;
  font-size: 13px;
`;

const Copyright = styled.div`
  letter-spacing: 0.5px;

  line-height: 16px;
  font-size: 12px;
`;

export default AppFooter;
